* {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
  box-sizing: border-box;
}

.main {
  max-width: 1812px;
  padding: 10px 50px;
  margin: 0 auto;
}

button {
  cursor: pointer;
}

.login-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}