.request-table {
    overflow-x: auto;
}
.request-table .scroll-box {
    min-width: 1024px;
}
.request-table .header .column:nth-child(1),
.request-table .body .row .column:nth-child(1) {
    width: 30px;
    min-width: 30px;
    padding-left: 15px;
    overflow: unset!important;
}
.request-table .header .column:nth-child(2),
.request-table .body .row .column:nth-child(2),
.request-table .header .column:nth-child(3),
.request-table .body .row .column:nth-child(3) {
    width: calc(40% - 234px / 2);
    min-width: calc(40% - 234px / 2);
}
.request-table .header .column:nth-child(4),
.request-table .body .row .column:nth-child(4),
.request-table .header .column:nth-child(5),
.request-table .body .row .column:nth-child(5) {
    width: 10%;
    min-width: 10%;
}
.request-table .header .column:nth-child(6),
.request-table .body .row .column:nth-child(6) {
    width: 154px;
    min-width: 154px;
    overflow: unset!important;
}
.request-table .header {
    display: flex;
    border: 1px solid #0000001A;
    border-radius: 8px;
    min-height: 80px;
    align-items: center;
    gap: 10px;
}
.request-table .header .column {
    display: flex;
    gap: 3px;
    flex-direction: column;
}
.request-table .header .column span {
    font-size: 12px;
    color: #656565;
    line-height: 12px;
}
.request-table .body .row, .request-table .header .row {
    display: flex;
    border-bottom: 1px solid #0000001A;
    min-height: 88px;
    align-items: center;
    gap: 10px;
}
.request-table .body .row .column {
    font-size: 16px;
    gap: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.request-table .body .row .column .status {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.request-table .body .row .column .status.success {
    color: #0AAF60;
}
.request-table .body .row .column .status.error {
    color: #F44336;
}
.request-table .body .row .column .status.processed {
    color: #F2A646;
}
.request-table .body .row .column .status svg {
    fill: #0000008A;
}
.request-table .body .row .column.btn-groups {
    display: flex;
    gap: 6px;
}
.request-table .body .row .column.btn-groups .action-btn {
    height: 36px;
    width: 36px;
    border: 1px solid #CCCCCC;
    border-radius: 36px;
    color: #CCCCCC;
    transition: all ease-out 0.1s;
    justify-content: center;
    align-items: center;
    display: flex;
    background: white;
    cursor: pointer;
}
.request-table .body .row .column.btn-groups .action-btn.green:hover {
    background: #0AAF60;
    box-shadow: 2px 10px 20px 0 #0AAF6080;
    color: white;
    border-color: #0AAF60;
}
.request-table .body .row .column.btn-groups .action-btn.yellow:hover {
    background: #F2A646;
    box-shadow: 2px 10px 20px 0 #FFAB2E80;
    color: white;
    border-color: #F2A646;
}
.request-table .body .row .column.btn-groups .action-btn.red:hover {
    background: #F44336;
    box-shadow: 2px 10px 20px 0 #F4433680;
    color: white;
    border-color: #F44336;
}
.r-btn {
    background: #6765F2;
    padding: 12px 22px;
    border-radius: 4px;
    gap: 8px;
    color: white;
    transition: all ease 0.2s;
    border: none;
}
.r-btn:hover {
    opacity: 0.85;
}
.r-btn:disabled:hover {
    opacity: 1;
}
.r-btn.process {
    background: #F44336;
}
.r-btn.disabled {
    background: #B3B3B3;
    cursor: default;
}
.r-btn.download {
    background: #0AAF60;
}
.r-btn.continue {
    background: #F2A646;
}
.parser-header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
}
.parser-header h1 {
    font-size: 26px;
}
.parser-header:nth-child(1) {
    border-bottom: 1px solid #0000001A;
}
.search-field {
    position: relative;
    max-width: 402px;
    width: 100%;
}
.search-field input {
    border: 1px solid #0000001A;
    max-width: 402px;
    height: 50px;
    border-radius: 4px;
    padding: 0 42px 0 26px;
    position: relative;
    width: 100%;
}
.search-field input::placeholder {
    color: #656565;
}
.search-field input:focus, .search-field input:hover {
    outline: 1px solid #6765F2;
    outline-offset: -2px;
}
.search-field svg {
    position: absolute;
    right: 16px;
    top: 15px;
    cursor: pointer;
    fill: #989898;
}
.search-field svg:hover {
    fill: #6765F2;
}
.search-field input:hover + svg,
.search-field input:focus + svg {
    fill: #6765F2;
}
.parser-select {
    width: 100%;
    max-width: 378px;
}
#general_loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    display: none;
}
.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 400px;
    background: white;
    padding: 32px;
    display: flex;
    gap: 24px;
    flex-direction: column;
}
.popup .popup-header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #3F3F3F;
    align-items: center;
    padding: 1px;
}
.popup .popup-header svg {
    cursor: pointer;
    transition: all ease 0.2s;
}
.popup .popup-header svg:hover {
    color: #999;
}
.popup .popup-content {
    max-height: 370px;
    overflow-y: auto;
    padding: 1px;
}
.popup .popup-content form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.popup .popup-content form input {
    width: 100%;
    height: 45px;
    border: 1px solid #0000001A;
    border-radius: 4px;
    padding: 0 15px;
}
.popup .popup-content form input:focus,
.popup .popup-content form input:hover {
    outline: 1px solid #6765F2;
    outline-offset: -2px;
}
.popup .popup-content form input.error {
    border-color: red;
}
.popup .popup-content form button {
    width: 100%;
    height: 45px;
}
.no-items {
    justify-content: center;
}
hr {
    height: 1px;
    background: #dddddd;
    width: 100%;
    border: none;
}